<template>
	<div class="text-center">
		<v-dialog :value="active" persistent width="400" @input="close()">
			<v-card rounded="xl">
				<v-card-title class="headline">
					{{ title }}
				</v-card-title>
				<v-card-text>
					{{ message }}
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn color="secondary" text @click="doNotShowAgain()">
						{{ $t('search.doNowShowAgain') }}
					</v-btn>
					<v-btn color="primary" text @click="close()">
						{{ $t('search.close') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'PopUp',
	computed: {
		...mapGetters({
			active: 'popup/active',
			title: 'popup/title',
			message: 'popup/message'
		})
	},
	methods: {
		...mapActions('popup', ['close', 'doNotShowAgain'])
	}
}
</script>
